import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {PostRequest} from "../../model/requests";
import {Page, IdResponse, PostResponse, PostCommentResponse} from "../../model/responses";
import {FeedType} from "../../model/feed-type";

@Injectable()
export class PostService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getPost(postId: string): Observable<PostResponse> {
        return this.http.get<PostResponse>(`${environment.apiUrl}/posts/${postId}`);
    }

    getFeed(pageSize: number, pageIndex: number, type?: FeedType): Observable<Page<PostResponse>> {
        let params = new HttpParams()
            .set('pagination', pageSize + ',' + pageIndex)
        if (type) {
            params = params.set('type', type)
        }
        return this.http.get<Page<PostResponse>>(`${environment.apiUrl}/posts/feed`, {params: params});
    }

    getGamePosts(gameSlug: string, pageSize: number, pageIndex: number): Observable<Page<PostResponse>> {
        const params = {pagination: pageSize + ',' + pageIndex}
        return this.http.get<Page<PostResponse>>(`${environment.apiUrl}/posts/game/${gameSlug}`, {params: params});
    }

    getUserPosts(username: string, pageSize: number, pageIndex: number): Observable<Page<PostResponse>> {
        const params = {pagination: pageSize + ',' + pageIndex}
        return this.http.get<Page<PostResponse>>(`${environment.apiUrl}/posts/user/${username}`, {params: params});
    }

    createPost(request: PostRequest, attachments: Blob[]): Observable<IdResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        for (let i = 0; i < attachments.length; i++) {
            formData.append(`attachment-${i}`, attachments[i]);
        }
        return this.http.post<IdResponse>(`${environment.apiUrl}/posts`, formData);
    }

    deletePost(postId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/posts/${postId}`);
    }

    likePost(postId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/posts/${postId}/likes`, {});
    }

    unlikePost(postId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/posts/${postId}/likes`);
    }

    commentPost(postId: string, comment: string, parentId?: string): Observable<PostCommentResponse> {
        let params = new HttpParams()
        if (parentId) {
            params = params.set('parentId', parentId)
        }
        return this.http.post<PostCommentResponse>(`${environment.apiUrl}/posts/${postId}/comments`, {content: comment}, {params: params});
    }

    deleteComment(postId: string, commentId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/posts/${postId}/comments/${commentId}`);
    }

    likeComment(postId: string, commentId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/posts/${postId}/comments/${commentId}/likes`, {});
    }

    unlikeComment(postId: string, commentId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/posts/${postId}/comments/${commentId}/likes`);
    }
}
