import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class ScrollService {
  private scrollSubject = new Subject<number>();

  onScroll = this.scrollSubject.asObservable();

  emitScrollEvent(scrollTop: number) {
    this.scrollSubject.next(scrollTop);
  }
}
